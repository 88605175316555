@use 'sass:color';

@import 'normalize';
@import 'color';
@import 'type';

.light {
  .front {
    background: $color-amber-400;
    color: $color-slate-900;
    h1 {
      span {
        color: $color-amber-300;
      }
    }
    a {
      &:hover {
        background: $color-amber-50;
      }
    }
  }
  .resume, .general {
    background: $color-slate-200;
    color: $color-slate-900;
  }
  .resume-header {
    background: $color-amber-400;
    color: $color-slate-900;
  }
  .resume-body-inner, .general-body-inner {
    background: $color-slate-100;
  }
  .resume-section {
    border-top: 4px solid $color-amber-400;
    &:first-child {
      border-top: none;
    }
    h3 {
      &.bordered {
        border-top: 1px solid $color-amber-400;
      }
    }
  }
  .general-body-inner {
    a {
      &:hover {
        background: $color-amber-400;
      }
    }
  }
  @media (min-width: 768px) {
    .front {
      h1 {
        span {
          color: $color-amber-200;
        }
      }
    }
    .front-thing {
      background: $color-amber-300;
    }
  }
}
.dark {
  .front {
    background: $color-slate-900;
    color: $color-amber-300;
    h1 {
      span {
        color: $color-slate-800;
      }
    }
    a {
      &:hover {
        background: $color-slate-600;
      }
    }
  }
  .resume, .general {
    background: $color-slate-900;
    color: $color-slate-200;
  }
  .resume-header {
    background: $color-amber-700;
    color: $color-slate-50;
  }
  .resume-body-inner, .general-body-inner {
    background: $color-slate-800;
  }
  .resume-section {
    border-top: 4px solid $color-amber-700;
    &:first-child {
      border-top: none;
    }
    h3 {
      &.bordered {
        border-top: 1px solid $color-amber-700;
      }
    }
  }
  .general-body-inner {
    a {
      &:hover {
       background: $color-amber-700;
      }
    }
  }
  @media (min-width: 768px) {
    .front {
      h1 {
        span {
          color: $color-slate-700;
        }
      }
    }
    .front-thing {
      background: $color-slate-800;
    }
  }
}
h1 {
  font-family: Hermes Maia Caps;
  font-feature-settings: 'case' 1, 'liga' 0;
  font-size: 3rem;
  font-weight: 200;
  line-height: 1;
  margin: 0 0 0 -6px;
  text-transform: uppercase;
}
h2 {
  font-family: Hermes Maia;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
  margin: 0;
}
h3 {
  font-family: Hermes Maia;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 1rem 0 0 0;
  &.bordered {
    padding-top: 1rem;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
ul, p {
  font-family: Valkyrie A;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 1rem 0 0 0;
}
li {
  margin: 0.5rem 0 0 0;
}
.front {
  .front-thing {
    padding: 2rem;
  }
  ul {
    font-family: Hermes Maia Caps;
    font-feature-settings: 'case' 1, 'liga' 0;
    font-size: 2rem;
    font-weight: 200;
    list-style: none;
    margin-top: 2rem;
    padding: 0;
  }
  a {
    margin: -.25rem;
    padding: .25rem;
    &:after {
      content: " →";
    }
    &:hover {
      transition: all 150ms linear;
    }
  }
}
.resume, .general {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.resume-header {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.resume-header-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1.5rem;
  width: 100%;
  ul {
    font-family: Hermes Maia;
    font-size: 18px;
    line-height: 28px;
    list-style: none;
    margin: 1rem 0 0 0;
    padding: 0;
  }
  li {
    margin: 0
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
.resume-body, .general-body {
  display: flex;
  justify-content: center;
}
.resume-body-inner, .general-body-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.5rem;
}
.resume-intro {
  font-family: Valkyrie A;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
  padding: 1rem 0 2rem;
}
.resume-section {
  padding: 2rem 0;
  h3 {
    &.bordered {
      padding-top: 1rem;
    }
  }
}
.resume-timespan {
  font-weight: 200;
}

.general-body-inner {
  padding: 2rem;
  h2 {
    margin: 2rem 0 0 0;
  }
  p {
    margin-top: .2rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      transition: all 150ms linear;
    }
  }
}

@media (min-width: 768px) {
  .front {
    display: flex;
    height: 100vh;
    .front-thing {
      justify-content: end;
      align-self: center;
      display: flex;
      width: 50%;
    }
  }
  .resume-header-inner, .resume-body-inner, .general-body-inner {
    max-width: 48rem;
  }
  .resume-header-inner {
    // align-items: center;
    // flex-direction: row;
    h1 {
      font-size: 3.75rem;
    }
    ul {
      list-style: none;
      margin: 0.5rem 0 0;
    }
    li {
      display: inline;
      & + li:before {
        content: "• "
      }
    }
  }
  .resume-intro {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .resume-section {
    h2 {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  }
}
  
@media (min-width: 1024px) {
  .resume-header-inner, .resume-body-inner, .general-body-inner {
    max-width: 56rem;
  }
}