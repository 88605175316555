// Matthew Butterick fonts ================================

// Hermes Maia
// @font-face {
//   font-family: Hermes Maia Caps;
//   font-style: normal;
//   font-weight: 800;
//   font-stretch: normal;
//   font-display: auto;
//   src: url('/assets/fonts/hermes_maia_8_caps_regular.woff2') format('woff2');
// }

// @font-face {
//   font-family: Hermes Maia;
//   font-style: normal;
//   font-weight: 700;
//   font-stretch: normal;
//   font-display: auto;
//   src: url('/assets/fonts/hermes_maia_7_regular.woff2') format('woff2');
// }

// @font-face {
//   font-family: Hermes Maia;
//   font-style: italic;
//   font-weight: 700;
//   font-stretch: normal;
//   font-display: auto;
//   src: url('/assets/fonts/hermes_maia_7_italic.woff2') format('woff2');
// }

@font-face {
  font-family: Hermes Maia;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_6_regular.woff2') format('woff2');
}
@font-face {
  font-family: Hermes Maia;
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_6_italic.woff2') format('woff2');
}

@font-face {
  font-family: Hermes Maia;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_4_regular.woff2') format('woff2');
}
@font-face {
  font-family: Hermes Maia;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_4_italic.woff2') format('woff2');
}

// @font-face {
//   font-family: Hermes Maia;
//   font-style: normal;
//   font-weight: 300;
//   font-stretch: normal;
//   font-display: auto;
//   src: url('/assets/fonts/hermes_maia_3_regular.woff2') format('woff2');
// }
// @font-face {
//   font-family: Hermes Maia;
//   font-style: italic;
//   font-weight: 300;
//   font-stretch: normal;
//   font-display: auto;
//   src: url('/assets/fonts/hermes_maia_3_italic.woff2') format('woff2');
// }

@font-face {
  font-family: Hermes Maia Caps;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_2_caps_regular.woff2') format('woff2');
}

@font-face {
  font-family: Hermes Maia;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_2_regular.woff2') format('woff2');
}
@font-face {
  font-family: Hermes Maia;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/hermes_maia_2_italic.woff2') format('woff2');
}

// Valkyrie A
@font-face {
  font-family: 'Valkyrie A';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Valkyrie A';
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Valkyrie A';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Valkyrie A';
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_bold_italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Valkyrie A Caps';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_caps_regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Valkyrie A Caps';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  font-display: auto;
  src: url('/assets/fonts/valkyrie_a_caps_bold.woff2') format('woff2');
}
